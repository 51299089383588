import httpService from "@/request"

// 商品
// 店铺
export function shopList(params) {
	return httpService({
		url: `/user/shopShop/list`,
		method: 'get',
		params: params,
	})
}
export function shopInsert(params) {
	return httpService({
		url: `/user/shopShop/insert`,
		method: 'post',
		data: params,
	})
}
export function shopDel(params) {
	return httpService({
		url: `/user/shopShop/delete`,
		method: 'post',
		data: params,
	})
}
export function shopUpdate(params) {
	return httpService({
		url: `/user/shopShop/update`,
		method: 'post',
		data: params,
	})
}
