<template>
  <div>
    <div class="cardTitle">店铺管理</div>
    <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm>
    <a-button style="margin: 10px" class="add-btn" @click="handlerAdd"
      >新增店铺</a-button
    >
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="edit(row)">修改</a>
          <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
        </a-space>
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="del"> 批量删除 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <a-drawer
      :title="activeMode == 1?'新增店铺':'修改店铺'"
      :width="720"
      :visible="drawer.show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item prop="shopName" label="店铺名称">
            <a-input
              v-model="form.shopName"
              placeholder="请输入店铺名称"
              style="width: 50%"
            ></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
	shopList,
	shopInsert,
	shopDel,
	shopUpdate,
} from "@/api/shop/shop";
import { formItem, columns, pagination } from "./depend/config";
import { form, rules } from "./depend/form.js";
export default {
	data() {
		return {
			drawer: {
				show: false,
				title: "新增店铺"
			},
			tableData: [],
			searchForm: {
				name: undefined,
			},
			formItem,
			columns,
			form,
			rules,
			pagination,
			// 选择的index
			selectedRowKeys: [],
			activeMode: 1,//1新增2修改
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		search(data){
			this.searchForm = data;
			this.getData()
		},
		async getData() {
			let res = await shopList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
		},
		edit(data) {
			this.activeMode = 2;
			this.form.shopName = data.shopName
			this.form.id = data.id
			this.drawer.show = true;
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async () => {
					let res = await shopDel({ ids: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		// 选择，获取id
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		// 批量操作
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.selectedRowKeys);
			}
		},
		// 新增
		handlerAdd() {
			this.activeMode = 1;
			this.form.shopName = '';
			this.form.id = undefined;
			this.drawer.show = true
		},
		// 跳转
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.drawer.show = false;
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.activeMode == 1) {
						let res = await shopInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.addClose();
							this.getData();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await shopUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.addClose();
							this.getData();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
</style>
